import React, { Fragment, Component } from "react";
import Fade from "react-reveal/Fade";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { auth } from "../../firebase/";
import { WhiteNavigationBar } from "../../components/navigation";
import Footer from "../../components/banner/index";

import {
  TwoColumnSection,
  CopySection,
  CenteredSection,
  Img,
  TitleSection,
} from "../../style/base-components";
import {
  SectionTitle,
  LongSectionTitle,
  Paragraph,
  ThinParagraph,
  LongSectionTitleNoPadding,
  ResourceLink,
  B,
} from "../../style/text";
import { Wrapper } from "./style";

import One from "../../assets/resources/1course.png";
import Two from "../../assets/resources/2one.png";
import Three from "../../assets/resources/3message.png";
import Four from "../../assets/resources/4reading.png";
import Five from "../../assets/resources/5tools.png";
import Six from "../../assets/resources/6manager.png";

import Separator from "../../components/separator/index";

class Resources extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNav: false,
      formSuccess: false,
    };
  }

  componentDidMount() {
    /* if (auth.isSignInWithEmailLink(window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      var email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt("Please provide your email for confirmation");
      }
      // The client SDK will parse the code from the link for you.
      auth
        .signInWithEmailLink(email, window.location.href)
        .then(function(result) {
          // Clear email from storage.
          //window.localStorage.removeItem("emailForSignIn");
          window.localStorage.setItem("loggedIn", "true");
          this.props.history.push("/resources");
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
        })
        .catch(function(error) {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    } else {
      auth.onAuthStateChanged((user) => {
        if (!user) {
          // User is signed in.
          this.props.history.push("/");
        }
      });
    } */
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Quilter resources | Spill</title>
          <meta
            name="description"
            content="Spill offers message-based therapy to make employees happier and more emotionally intelligent."
          />
          <meta
            name="keywords"
            content="Spill,Mental health,counselling,counsellor,chat,online,therapy,help"
          />
        </Helmet>
        <Footer />
        <TitleSection>
          <SectionTitle centered>Your Spill resources</SectionTitle>
        </TitleSection>

        <TwoColumnSection>
          <CopySection>
            <Fade>
              <Img src={Three} alt="MainMenu" />
            </Fade>
            <LongSectionTitleNoPadding>
              See real advice from therapists
            </LongSectionTitleNoPadding>
            <ThinParagraph>
              Search any question about mental health or emotional wellbeing.
              And if you can't find the right answer, you can just ask for more
              information to be added.
            </ThinParagraph>
            <ResourceLink to="question">Browse advice ➔</ResourceLink>
          </CopySection>
          <CopySection>
            <Fade>
              <Img src={Five} alt="Video Therapy" />
            </Fade>
            <LongSectionTitleNoPadding>
              Try our mental health tools and exercises
            </LongSectionTitleNoPadding>
            <ThinParagraph>
              Browse a range of exercises created by therapists to help you
              change how you think, work, feel and behave.
            </ThinParagraph>
            <ResourceLink to="/tools">Browse tools ➔</ResourceLink>
          </CopySection>
        </TwoColumnSection>

        <TwoColumnSection>
          <CopySection>
            <Fade>
              <Img src={Four} alt="Onboarding" />
            </Fade>
            <LongSectionTitleNoPadding>
              Browse our content recommendations
            </LongSectionTitleNoPadding>
            <ThinParagraph>
              Look through our curated list of resources, designed to help you
              learn and grow. This quarters are all watchable online.
            </ThinParagraph>
            <ResourceLink to="/reading">Browse recommendations ➔</ResourceLink>
          </CopySection>
        </TwoColumnSection>

        <Separator />
        <CenteredSection>
          <Fragment>
            <SectionTitle>FAQs</SectionTitle>

            <Wrapper>
              <LongSectionTitle>
                If I ask a question does it remain anonymous?
              </LongSectionTitle>
              <Paragraph>
                Yes. You can ask a question anonymously. We only take a personal
                email address to send a notification when we've added your
                response to the list. We also make sure to remove any personally
                identifiable information from the response.
              </Paragraph>
              <LongSectionTitle>
                When will I hear back if I send a question to a therapist?
              </LongSectionTitle>
              <Paragraph>
                You will hear back within one working day if you ask before 12
                noon or two working days if you ask a question later in the day.
                <LongSectionTitle>
                  I want to speak to somebody on a longer-term basis, where can
                  I do that?
                </LongSectionTitle>
                <Paragraph>
                  You can access Quilter's EAP by calling them on 0800 917 9330.
                </Paragraph>
                <LongSectionTitle>
                  I need to speak to somebody immediately.
                </LongSectionTitle>
                <Paragraph>
                  To talk to someone right now, you can call Samaritans
                  anonymously on 116 123. The line is for anyone going through a
                  difficult moment.
                </Paragraph>
                <br />
                <br />
                <br />
                If you have a question that hasn't been answered then you can
                email us on <B>hi@spill.chat</B>
              </Paragraph>
            </Wrapper>
          </Fragment>
        </CenteredSection>
      </Fragment>
    );
  }
}

export default withRouter(Resources);
