import React, { Fragment, Component } from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import * as qs from "query-string";

import {
  TwoColumnSection,
  DrawingPadding,
  CenteredSection,
  LinkText,
  TitleSection,
  Section,
  Img,
} from "../../style/base-components";
import {
  SectionTitle,
  LongSectionTitle,
  Paragraph,
  SubTitle,
  ThinParagraph,
  SmallHeading,
  ExternalResourceLink,
  LongSectionTitleNoPadding,
  B,
} from "../../style/text";
import {
  ListItem,
  ItemIcon,
  ItemTitle,
  ItemDescription,
  TabWrapper,
  TabItem,
  TabContainer,
} from "./style";
import { WhiteNavigationBar } from "../../components/navigation";

import Top from "../../assets/tools/top.png";
import Circle from "../../assets/tools/circle.png";
import Grid from "../../assets/tools/grid.png";
import DramaTriangle from "../../assets/tools/drama-triangle.png";
import HabitChange from "../../assets/tools/habit-change.png";
import FiveWhys from "../../assets/tools/5-whys.png";
import DepressionSupport from "../../assets/tools/depression-support.png";
import AnxietySupport from "../../assets/tools/anxiety-support.png";

import Separator from "../../components/separator/index";
import theme from "../../style/theme";

class Tools extends Component {
  constructor(props) {
    super(props);
    this.state = {
      section: 0,
    };
    this.handleTabChange = this.handleTabChange.bind(this);
  }

  componentDidMount() {}

  handleTabChange(value) {
    this.setState({ section: value });
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Tools | Spill</title>
          <meta
            name="description"
            content="Spill offers message-based therapy to make employees happier and more emotionally intelligent."
          />
          <meta
            name="keywords"
            content="Spill,Mental health,counselling,counsellor,chat,online,therapy,help"
          />
        </Helmet>
        <WhiteNavigationBar showNav />
        <TitleSection>
          <SectionTitle centered>Tools and exercises</SectionTitle>
        </TitleSection>
        <TabWrapper>
          <TabContainer>
            <TabItem
              onClick={() => this.handleTabChange(0)}
              active={this.state.section == 0}
            >
              <SubTitle centered>Relationships</SubTitle>
            </TabItem>
            <TabItem
              onClick={() => this.handleTabChange(1)}
              active={this.state.section == 1}
            >
              <SubTitle centered>Habits</SubTitle>
            </TabItem>
            <TabItem
              onClick={() => this.handleTabChange(2)}
              active={this.state.section == 2}
            >
              <SubTitle centered>Self-understanding</SubTitle>
            </TabItem>
            <TabItem
              onClick={() => this.handleTabChange(3)}
              active={this.state.section == 3}
            >
              <SubTitle centered>Supporting others</SubTitle>
            </TabItem>
          </TabContainer>
        </TabWrapper>

        {(() => {
          if (this.state.section == 0) {
            return (
              <div>
                <TwoColumnSection>
                  <div>
                    <LongSectionTitleNoPadding>
                      Circles of Connection Exercise
                    </LongSectionTitleNoPadding>
                    <Paragraph>
                      An exercise to audit your social life (15 min)
                    </Paragraph>
                    <ExternalResourceLink href="https://www.spill.chat/tools/relationships/circles-of-connection.pdf">
                      Get a printable PDF ➔
                    </ExternalResourceLink>
                    <DrawingPadding
                      src={Circle}
                      alt="Four cicles inside each other"
                    />
                  </div>

                  <ListItem>
                    <ItemDescription>
                      <B>Step 1: Draw</B>
                      Print off or draw out the four circles in the image.
                      <br />
                      <br />
                      <B>Step 2: Write</B>
                      Open your calendar and go through the last month. Every
                      time you spent time (30min+) interacting with a person or
                      a group of people, write their name(s) down in the
                      appropriate circle. If you spent time with them multiple
                      times, write a tally next to their name, e.g. Charlie III.
                      Remember to include familiar faces — hairdressers, clients
                      at work, any significant human interaction — as well as
                      long phone or video calls. WhatsApping doesn’t count!
                      <br />
                      <br />
                      <B>Step 3: Analyse</B>
                      Look back over the circles. If there are a large number of
                      1s and 2s, your time and attention is being allocated in a
                      way that’s most likely to make you feel content. If there
                      are mostly 3s and 4s, something’s not quite right. You’re
                      spending a lot of your social time with people you don’t
                      feel connected to. This means you might be experiencing
                      the paradoxical situation of feeling isolated despite
                      having an active social life.
                      <br />
                      <br />
                      <B>Step 4: Decide</B>
                      If that’s the case, think about whether there’s anyone in
                      zones 3 and 4 you would like to be closer to. Draw arrows
                      from their name towards the zone you’d like to get them
                      to. Similarly, if there’s anyone in zones 1 or 2 that
                      doesn’t nourish you or bring you joy, draw arrows from
                      their name towards the outer zones - or think about
                      crossing their name off altogether.
                    </ItemDescription>
                  </ListItem>
                </TwoColumnSection>
                <Separator />
                <TwoColumnSection>
                  <div>
                    <LongSectionTitleNoPadding>
                      The Drama Triangle
                    </LongSectionTitleNoPadding>
                    <Paragraph>
                      An exercise to help you better understand arguments with
                      your partner (5 min)
                    </Paragraph>
                    <ExternalResourceLink href="https://www.spill.chat/tools/relationships/drama-triangle.pdf">
                      Get a printable PDF ➔
                    </ExternalResourceLink>
                    <DrawingPadding
                      src={DramaTriangle}
                      alt="A triangle with Rescuer, Persecutor and Victim on the three corners"
                    />
                  </div>

                  <ListItem>
                    <ItemDescription>
                      Often get stuck in the same arguments and patterns with a
                      partner, best friend or family member? The drama triangle
                      can help you understand why, and help you break out of it.
                      <br />
                      <br />
                      <B>Step 1: Understand the triangle</B>
                      All of us naturally tend to one of these three positions
                      in life in general, and in a relationship in particular.
                      When one person takes up a role (e.g. the victim), it
                      naturally forces the other person to play the opposing
                      role (e.g. persecutor).
                      <br />
                      <br />
                      <B>Step 2: Identify your role</B>
                      Look back at key moments or recent arguments in a
                      particular relationship and think about what kind of
                      thoughts were going through your mind. Which of the three
                      roles do you identify most with?
                      <br />
                      <br />
                      <B>Step 3: Break out</B>
                      The key to breaking out is to, whatever role you’re in,
                      stop making the other person responsible for your
                      emotions. For persecutors, that means stopping the
                      constant blame of others, and starting to take
                      responsibility themselves. For rescuers, that means
                      understanding that they’re doing things to serve
                      themselves (not really to serve others) — it’s possible to
                      be supportive without rescuing. For victims, this means
                      taking over their own decisions instead of deferring them
                      to others.
                    </ItemDescription>
                  </ListItem>
                </TwoColumnSection>
              </div>
            );
          } else if (this.state.section == 1) {
            return (
              <div>
                <TwoColumnSection>
                  <div>
                    <LongSectionTitleNoPadding>
                      100 Blocks Exercise
                    </LongSectionTitleNoPadding>
                    <Paragraph>
                      An exercise to audit how you spend your free time (15 min)
                    </Paragraph>
                    <ExternalResourceLink href="https://www.spill.chat/tools/hobbies-and-habits/100-blocks.pdf">
                      Get a printable PDF ➔
                    </ExternalResourceLink>
                    <DrawingPadding
                      src={Grid}
                      alt="A large square split up into 100 blocks"
                    />
                  </div>

                  <ListItem>
                    <ItemDescription>
                      <B>Step 1: Print</B>
                      Print off two copies of the 100 blocks image shown here,
                      or draw a 10x10 square similar to it on a blank piece of
                      paper.
                      <br />
                      <br />
                      <B>Step 2: Label</B>
                      Fill in the unchangeable blocks (like work and commuting).
                      In all the other blocks, write what you think the purpose
                      of each should be (e.g. learning, relaxing, connecting
                      with others, being active).
                      <br />
                      <br />
                      <B>Step 3: Weigh up</B>
                      Think about the trade-offs you're making, and critique
                      your own thinking. Is cooking really something you enjoy
                      enough to spend four blocks on? Is 10 mins of meditation a
                      day important enough to spend a block on? Then think about
                      which blocks should be treated as non-negotiable in their
                      labeled purpose and which should be more flexible? Which
                      blocks should be left blank, with no assigned purpose at
                      all?
                      <br />
                      <br />
                      <B>Step 4: Label again</B>
                      Then fill out another square of 100 blocks with how you
                      spent that time yesterday. How are the two squares
                      different? Why?
                    </ItemDescription>
                  </ListItem>
                </TwoColumnSection>
                <Separator />
                <TwoColumnSection>
                  <div>
                    <LongSectionTitleNoPadding>
                      Habit Change Framework
                    </LongSectionTitleNoPadding>
                    <Paragraph>
                      An exercise to help you change a bad habit (10 min)
                    </Paragraph>
                    <ExternalResourceLink href="https://www.spill.chat/tools/hobbies-and-habits/habit-change.pdf">
                      Get a printable PDF ➔
                    </ExternalResourceLink>
                    <DrawingPadding
                      src={HabitChange}
                      alt="When _, I will _, because it provides me with _."
                    />
                  </div>

                  <ListItem>
                    <ItemDescription>
                      <B>Step 1: Find the cue</B>
                      When you feel the urge for your habit, ask yourself… What
                      time is it? Where are you? Who else is around? What did
                      you just do? What emotion are you feeling? One of these
                      five things is the cue: look for which one stays the same
                      every time you feel the urge.
                      <br />
                      <br />
                      <B>Step 2: Find the real reward</B>
                      What craving do you think your habit is satisfying? Change
                      the reward to find out. For example, having a cookie might
                      be satisfying the craving for a hand-to-mouth gesture. Try
                      having a coffee instead. Is the craving gone? If not,
                      think of another reward. Maybe the cookie is satisfying
                      the craving for a break from work. Try taking a walk
                      instead. Bingo. You’ve found the real reward: a break from
                      work.
                      <br />
                      <br />
                      <B>Step 3: Find a new routine</B>
                      Now that you’ve found the real reward, think of options
                      for a replacement routine. Using the above example, a walk
                      is one option. Making a phone call outside could be
                      another, or having a short conversation with a colleague.
                      Try to find a routine that you could feasibly do every
                      time you used to do the old routine.
                      <br />
                      <br />
                      <B>Step 4: Commit to the plan</B>
                      Print off the image or copy it out by hand and fill in
                      your new plan. Put it up somewhere you’ll see it every
                      day, like on your computer screen at work. Try it for a
                      week. Eventually, studies say, the new behaviour will
                      become automatic.
                    </ItemDescription>
                  </ListItem>
                </TwoColumnSection>
              </div>
            );
          } else if (this.state.section == 2) {
            return (
              <div>
                <TwoColumnSection>
                  <div>
                    <LongSectionTitleNoPadding>
                      ‘5 Whys’ Mind Mapping Exercise
                    </LongSectionTitleNoPadding>
                    <Paragraph>
                      An exercise to work out what’s behind your emotions (20
                      min)
                    </Paragraph>
                    <ExternalResourceLink href="https://www.spill.chat/tools/self-understanding/5-whys.pdf">
                      Get a printable PDF ➔
                    </ExternalResourceLink>
                    <DrawingPadding
                      src={FiveWhys}
                      alt="Blob with 'surface emotion' connected to other blobs with 'reason why'"
                    />
                  </div>

                  <ListItem>
                    <ItemDescription>
                      <B>Step 1: Label your emotions</B>
                      Write down five emotions you’re feeling right now on a
                      blank page. Circle each emotion.
                      <br />
                      <br />
                      <B>Step 2: Keep asking why</B>
                      Start with one emotion first (e.g. frustrated). Draw an
                      arrow off it and write one hypothesis on why you think
                      you’re feeling this emotion (e.g. work). Then draw another
                      arrow off this and ask why again (e.g. not progressing).
                      Keep going (e.g. haven’t had any training opportunities).
                      Keep going as long as you can on each ‘branch’.At any
                      point, feel free to add multiple ‘whys’ that branch off
                      with separate arrows. When you’ve finished, leave the mind
                      map for today.
                      <br />
                      <br />
                      <B>Step 3: Come back later and analyse</B>
                      Come back to it tomorrow with a clear mind, and go through
                      your logic steps with a critical hat on. Try to spot any
                      pieces of reasoning that might be down to cognitive biases
                      — that might be your mind inferring something from the
                      facts. E.g. is it really true you haven’t had any training
                      opportunities, or is this just dichotomous thinking (all
                      or nothing)? Were there opportunities to learn on the job?
                      Could you have pushed harder for a training budget? Are
                      there free training opportunities you could pitch? Is
                      there anything you could teach yourself using YouTube
                      tutorials?
                    </ItemDescription>
                  </ListItem>
                </TwoColumnSection>
                <Separator />
                <TwoColumnSection>
                  <div>
                    <LongSectionTitleNoPadding>
                      ‘Top, Tail, Learning’ Journalling Technique
                    </LongSectionTitleNoPadding>
                    <Paragraph>
                      An exercise to develop gratitude and awareness (10 min)
                    </Paragraph>
                    <ExternalResourceLink href="https://www.spill.chat/tools/self-understanding/top-tail-learning.pdf">
                      Get a printable PDF ➔
                    </ExternalResourceLink>
                    <DrawingPadding
                      src={Top}
                      alt="One good thing, one bad thing, and one thing you learnt from your day split into three rectangles
              "
                    />
                  </div>

                  <ListItem>
                    <ItemDescription>
                      Describe one good thing, one bad thing, and one thing you
                      learnt from either today (if you’re journalling before
                      bed) or yesterday (if you’re journalling in the morning).
                      <br />
                      <br />
                      <B>Tip 1</B>
                      We recommend doing it before you go to bed (writing about
                      that day) or early in the morning (writing about the
                      previous day).
                      <br />
                      <br />
                      <B>Tip 2</B>
                      Keep it short and simple: it’s better to get into the
                      habit of journalling often rather than writing a
                      beautifully crafted essay every few weeks.
                      <br />
                      <br />
                      <B>Tip 3</B>
                      It doesn’t need to be in proper prose: write notes, random
                      word associations that come to mind, do little sketches
                      and doodles, make graphs…
                    </ItemDescription>
                  </ListItem>
                </TwoColumnSection>
              </div>
            );
          } else {
            return (
              <div>
                <TwoColumnSection>
                  <div>
                    <LongSectionTitleNoPadding>
                      General principles for those struggling with depression
                    </LongSectionTitleNoPadding>
                    <Paragraph>
                      A checklist to help support someone you know with
                      depression (2 min)
                    </Paragraph>
                    <ExternalResourceLink href="https://www.spill.chat/tools/supporting-others/depression-support-checklist.pdf">
                      Get a printable PDF ➔
                    </ExternalResourceLink>
                    <DrawingPadding
                      src={DepressionSupport}
                      alt="text: A checklist to help support someone you know with depression
              "
                    />
                  </div>

                  <ListItem>
                    <ItemDescription>
                      <B>Tip 1</B>
                      Message, don’t call. Even answering a phone call can be a
                      lot for someone who’s going through a hard time.
                      <br />
                      <br />
                      <B>Tip 2</B>
                      Send voice notes on WhatsApp for a more personal touch:
                      talk about yourself, tell them a funny anecdote, send them
                      well wishes, say you’re thinking about them, ask
                      rhetorical but not specific questions, recall shared
                      memories.
                      <br />
                      <br />
                      <B>Tip 3</B>
                      If they do want to talk, concentrate on really listening
                      to them, communicate non-judgmentally, empathise as much
                      as you can, and don’t draw comparisons to your own or
                      others’ experiences.
                      <br />
                      <br />
                      <B>Tip 4</B>
                      Post them something nice they can enjoy on their own, or
                      something you know they like — a book or magazine works
                      well, because it’s engaging but not super high-effort.
                      Avoid things that might feel like a lot of work (e.g. a
                      blank journal).
                      <br />
                      <br />
                      <B>Tip 5</B>
                      If they’re up for it, talk them through the kinds of
                      support or tools available to them — positive mental
                      health practices, therapy, going to the GP.
                    </ItemDescription>
                  </ListItem>
                </TwoColumnSection>
                <Separator />
                <TwoColumnSection>
                  <div>
                    <LongSectionTitleNoPadding>
                      General principles for those with anxiety
                    </LongSectionTitleNoPadding>
                    <Paragraph>
                      A checklist to help support someone you know with anxiety
                      (2 min)
                    </Paragraph>
                    <ExternalResourceLink href="https://www.spill.chat/tools/supporting-others/anxiety-support-checklist.pdf">
                      Get a printable PDF ➔
                    </ExternalResourceLink>
                    <DrawingPadding
                      src={AnxietySupport}
                      alt="text: A checklist to help support someone you know with anxiety
              "
                    />
                  </div>

                  <ListItem>
                    <ItemDescription>
                      <B>Tip 1</B>
                      Remember they aren’t choosing to feel this way, so avoid
                      trying to find practical solutions or encourage them to do
                      more than they’re comfortable with.
                      <br />
                      <br />
                      <B>Tip 2</B>
                      Remember that anxiety is irrational, so avoid trying to
                      dispel their worries with ‘logical’ or ‘rational’
                      arguments: it won’t work, and it could only exacerbate
                      their situation.
                      <br />
                      <br />
                      <B>Tip 3</B>
                      In a quiet moment where they don’t seem actively worked
                      up, ask about their experience. Ask how anxiety affects
                      their day-to-day life, what makes it worse, and what makes
                      it better. Listen to them fully, and try to empathise with
                      what they have to go through.
                      <br />
                      <br />
                      <B>Tip 4</B>
                      If they have or begin a panic attack while you’re with
                      them, gently let them know that they might be having a
                      panic attack and that you’re here for them. Encourage them
                      to breathe slowly, in sync with you raising your arm
                      slowly up and down. Suggest they sit somewhere quiet until
                      they feel better, and sit with them.
                      <br />
                      <br />
                      <B>Tip 5</B>
                      If they’re up for it, talk them through the kinds of
                      support or tools available to them: positive mental health
                      practices, CBT, going to the GP.
                    </ItemDescription>
                  </ListItem>
                </TwoColumnSection>
              </div>
            );
          }
        })()}

        <Section />
      </Fragment>
    );
  }
}

export default withRouter(Tools);
