import styled from "styled-components";
import devices from "../../style/devices";
import { Link } from "react-router-dom";
import { Section } from "../../style/base-components";
import { SectionTitle } from "../../style/text";
import { Paragraph } from "../../style/text";

export const Container = styled.div``;
export const Form = styled.form`
  padding: ${({ theme }) => `${theme.spacing.m1} 0`};
  display: flex;
  flex-direction: column;
  width: 100%;
  @media ${devices.tablet} {
    width: 100%;
    margin: 0 auto;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media ${devices.tablet} {
    max-width: 35rem;
  }
`;
export const BigWrapper = styled(Wrapper)`
  min-height: 90vh;
  @media ${devices.tablet} {
    min-height: 50vh;
  }
`;
export const ErrorMessage = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.tertiary.normal};
  padding-top: ${({ theme }) => theme.spacing.s2};
`;

export const Input = styled.textarea`
  resize: none;
  position: relative;
  width: auto;
  background-color: ${({ theme }) => theme.colors.white};
  outline: 0;
  border: 0;
  border: solid 0.1rem ${({ theme }) => theme.colors.grey[300]};
  border-radius: 0.5rem;
  font: ${({ theme }) => theme.font.size[3]} Arial;
  height: 20vh;
  padding: ${({ theme }) => theme.spacing.xs3};
`;

export const EmailInput = styled.input`
  font-family: ${({ theme }) => theme.font.family.lato};
  border: 0;
  border: solid 0.1rem;
  border-radius: 0.5rem;
  padding: ${({ theme }) => theme.spacing.xs3};
  font-size: ${({ theme }) => theme.font.size[3]};
  background-color: ${({ theme }) => theme.colors.white};
  border-color: ${({ theme }) => theme.colors.grey[300]};
  outline: 0;
  margin-top: ${({ theme }) => theme.spacing.s1};
  width: auto;
`;
export const CenteredDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const CenteredSection = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 90vh;

  @media ${devices.desktop} {
    min-height: 60vh;
  }
`;
