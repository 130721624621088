import React from "react";

import { ButtonSolid, ButtonBorder, ClearButton, ArrowWrapper } from "./style";

export const FillButton = ({ fillColor, fontColor, action, text, style }) => (
  <ButtonSolid
    onClick={action}
    fillColor={fillColor}
    fontColor={fontColor}
    style={{ ...style }}
  >
    {text}
  </ButtonSolid>
);

export const BorderButton = ({
  borderColor,
  fontColor,
  action,
  style,
  text,
}) => (
  <ButtonBorder
    style={{ ...style }}
    onClick={action}
    borderColor={borderColor}
    fontColor={fontColor}
  >
    {text}
  </ButtonBorder>
);
