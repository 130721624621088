import React, { Fragment } from "react";
import "babel-polyfill";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import ReactGA from "react-ga";
import { auth } from "./firebase/";
import ScrollToTop from "./scrollToTop.js";

ReactGA.initialize("UA-118977695-3");
ReactGA.pageview(window.location.pathname + window.location.search);

const history = createBrowserHistory();
history.listen((location, action) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

import Home from "./pages/home/index";
import Question from "./pages/question/index";
import Banner from "./components/banner/index";
import QuestionDatabase from "./pages/questionDatabase/index";
import QuestionAdvisor from "./pages/questionAdvisor/index";
import { WhiteNavigationBar } from "./components/navigation/index";
import Portal from "./pages/portal/index";
import Resources from "./pages/resources/index";
import Tools from "./pages/tools/index";
import Reading from "./pages/reading/index";
import NotFound from "./pages/404/index";

const PrivateRoute = ({ component: Component, ...rest }) => {
  // Add your own authentication on the below line.
  return (
    <Route
      {...rest}
      render={(props) =>
        window.localStorage.getItem("loggedIn") == "true" ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};

const AppRouter = () => (
  <Router history={history}>
    <ScrollToTop />
    <Switch>
      <Route exact path="/" component={Resources} />
      <Fragment>
        <Switch>
          <Route
            exact
            path="/ask-a-therapist-a-question"
            component={Question}
          />
          <Route exact path="/question-advisor" component={QuestionAdvisor} />
          <Route exact path="/question" component={QuestionDatabase} />
          <Route exact path="/resources" component={Resources} />
          <Route exact path="/tools" component={Tools} />
          <Route exact path="/reading" component={Reading} />
          <Route exact path="/404" component={NotFound} />
          <Route path="/*" render={Resources} />
        </Switch>
      </Fragment>
      <Route component={NotFound} />
    </Switch>
  </Router>
);

export default AppRouter;
