import React from "react";

import {
  Container,
  LogoWrapper,
  Nav,
  NavTitle,
  NavItem,
  NavLogo,
  IconWrapper,
  BottomFooter,
  BottomFooterText,
  FooterContent,
  BottomFooterLink,
} from "./style";
import logo from "../../assets/logo/logo-blob-teal.png";

const Footer = () => (
  <Container>
    <BottomFooter>
      <BottomFooterText>© 2020 Spill App Ltd</BottomFooterText>
      <BottomFooterText>
        <BottomFooterLink
          href="https://quilter.spill.chat/docs/terms-and-conditions.pdf"
          target="__blank"
        >
          {" "}
          Terms of service{" "}
        </BottomFooterLink>
        |
        <BottomFooterLink
          href="https://quilter.spill.chat/docs/privacy-policy.pdf"
          target="__blank"
        >
          {" "}
          Privacy policy{" "}
        </BottomFooterLink>
        |
        <BottomFooterLink href="mailto:hi@spill.chat">
          {" "}
          Contact us{" "}
        </BottomFooterLink>
      </BottomFooterText>
    </BottomFooter>
  </Container>
);

export default Footer;
