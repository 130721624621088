import React, { Fragment, Component } from "react";
import Fade from "react-reveal/Fade";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import * as qs from "query-string";
import { auth } from "../../firebase/";

import {
  ThreeColumns,
  Underline,
  Form,
  Input,
  Wrapper,
  LinkText,
  LinkTextTertiary,
  Stage,
  Reason,
  Description,
  IconWrapper,
  ErrorMessage,
  StoreContainer,
  StoreImg,
  ButtonContainer,
  CenteredDiv,
} from "./style";
import { BorderButton } from "../../components/buttons/index";
import LogoSection from "../../components/logoSection/index";
import { CenteredSection } from "../../style/base-components";
import { SectionTitle, Paragraph, ParagraphLink } from "../../style/text";
import theme from "../../style/theme";

class Home extends Component {
  constructor(props, history) {
    super(props, history);
    this.state = {
      showNav: false,
      email: "",
      formSuccess: false,
      error: false,
      formError: "",
    };
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.formValidation = this.formValidation.bind(this);
    this.domainValidation = this.domainValidation.bind(this);
    this.changePage = this.changePage.bind(this);
    this.signIn = this.signIn.bind(this);
  }

  componentDidMount() {
    /* auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        this.props.history.push("/resources");
      }
    }); */
    this.props.history.push("/resources");
  }
  submitForm(event) {
    event.preventDefault();
    if (this.formValidation() && this.domainValidation()) {
      this.signIn();
    }
  }
  onChangeEmail(event) {
    this.setState({ email: event.target.value });
  }
  domainValidation() {
    const allowedDomains = ["@spill.chat", "@quilter.com"];
    var domain = this.state.email.slice(
      this.state.email.indexOf("@"),
      this.state.email.length
    );
    if (allowedDomains.includes(domain) == false) {
      this.setState({
        error: true,
        formError: "Please use your Quilter email address",
      });
      return false;
    }
    return true;
    console.log(domain);
  }
  formValidation() {
    if (this.state.email.indexOf("@") == -1) {
      this.setState({
        error: true,
        formError: "Please enter a valid email",
      });
      return false;
    }
    return true;
  }
  changePage() {
    this.setState({ formSuccess: true });
  }

  signIn() {
    var userEmail = this.state.email;
    var actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be whitelisted in the Firebase Console.
      url: "https://quilter.spill.chat/resources",
      //url: "http://localhost:8080/resources",
      // This must be true.
      handleCodeInApp: true,
    };
    auth
      .sendSignInLinkToEmail(userEmail, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem("emailForSignIn", userEmail);
        //window.localStorage.setItem("loggedIn", "true");
        this.changePage();
      })
      .catch(function(error) {
        console.log("errerw", error);
        // Some error occurred, you can inspect the code: error.code
      });
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Quilter sign in | Spill</title>
          <meta
            name="description"
            content="Spill offers message-based therapy to make employees happier and more emotionally intelligent."
          />
          <meta
            name="keywords"
            content="Spill,Mental health,counselling,counsellor,chat,online,therapy,help"
          />
        </Helmet>
        <CenteredSection>
          {this.state.formSuccess ? (
            <Fragment>
              <LogoSection />
              <SectionTitle centered>Check your email address</SectionTitle>
              <Wrapper>
                <Paragraph centered>
                  We've sent a special link to your email. Click the link in
                  your email to confirm your email address and get started.
                </Paragraph>
                <ParagraphLink
                  centered
                  onClick={() => this.setState({ formSuccess: false })}
                >
                  Wrong email? Please re-enter your email.
                </ParagraphLink>
              </Wrapper>
            </Fragment>
          ) : (
            <Fragment>
              <LogoSection />
              <SectionTitle centered>Let's get you signed in</SectionTitle>
              <Wrapper>
                <Paragraph centered>
                  Enter your Quilter work email address so that we can sign you
                  in
                </Paragraph>
                <Form onSubmit={(event) => this.submitForm(event)}>
                  <label for="email" style={{ display: "none" }}>
                    Quilter Email Address
                  </label>
                  <Input
                    placeholder="Quilter Email Address"
                    type="email"
                    id="email"
                    value={this.state.email}
                    onChange={this.onChangeEmail}
                    title="email"
                    aria-label="
                    email"
                  />
                </Form>
                <CenteredDiv>
                  <BorderButton
                    text="confirm"
                    type="submit"
                    borderColor={theme.colors.blue.normal}
                    fontColor={theme.colors.blue.normal}
                    action={(event) => this.submitForm(event)}
                  />
                </CenteredDiv>

                {this.state.error && (
                  <ErrorMessage centered>{this.state.formError}</ErrorMessage>
                )}
              </Wrapper>
            </Fragment>
          )}
        </CenteredSection>
      </Fragment>
    );
  }
}

export default withRouter(Home);
