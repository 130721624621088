import React, { Fragment, Component } from "react";

import {
  Container,
  LogoWrapper,
  Nav,
  NavTitle,
  NavItem,
  NavLogo,
  IconWrapper,
  BottomFooter,
  BottomFooterText,
  FooterContent,
  BottomFooterLink,
} from "./style";
import logo from "../../assets/logo/logo-blob-teal.png";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cookiesAccepted: false,
    };
    this.updateCookies = this.updateCookies.bind(this);
  }
  componentDidMount() {
    if (window.localStorage.getItem("cookies") == "true") {
      this.setState({ cookiesAccepted: true });
    }
  }
  updateCookies() {
    window.localStorage.setItem("cookies", "true");
    this.setState({ cookiesAccepted: true });
  }

  render() {
    return (
      <div>
        {this.state.cookiesAccepted ? null : (
          <Container>
            <BottomFooter>
              <BottomFooterText>
                Spill uses cookies to allow us to better understand how the site
                is used. By continuing to use this site, you consent to this
                policy.
              </BottomFooterText>
              <BottomFooterText>
                <BottomFooterLink onClick={() => this.updateCookies()}>
                  {" "}
                  Okay{" "}
                </BottomFooterLink>
              </BottomFooterText>
            </BottomFooter>
          </Container>
        )}
      </div>
    );
  }
}

export default Footer;
