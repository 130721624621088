import React, { Fragment, Component } from "react";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  connectSearchBox,
  connectHits,
  Configure,
} from "react-instantsearch-dom";
import { Helmet } from "react-helmet";
import {
  SectionTitle,
  Paragraph,
  B,
  LongSectionSubTitle,
  SmallParagraph,
} from "../../style/text";
import {
  Container,
  LongCopy,
  SearchInput,
  Form,
  Input,
  Wrapper,
  BigWrapper,
  CenteredDiv,
  ErrorMessage,
  CenteredSection,
  Label,
  DisclaimerMessage,
  HitTopicBox,
} from "./style";
import { BorderButton } from "../../components/buttons/index";
import theme from "../../style/theme";
import * as qs from "query-string";
import { WhiteNavigationBar } from "../../components/navigation";
import Separator from "../../components/separator/index";

const searchClient = algoliasearch(
  "BFJ8VXPKU8",
  "e484d6d72bfe2d5fa8c1c29bb7426779"
);

class QuestionDatabase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      question: "",
      topics: "",
      formSuccess: false,
      error: false,
      formError: "",
      userType: "user",
    };
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeQuestion = this.onChangeQuestion.bind(this);
    this.onChangeTopics = this.onChangeTopics.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.formValidation = this.formValidation.bind(this);
  }
  onChangeEmail(event) {
    this.setState({ email: event.target.value });
  }

  onChangeQuestion(event) {
    this.setState({ question: event.target.value });
  }
  onChangeTopics(event) {
    this.setState({ topics: event.target.value });
  }

  formValidation() {
    if (this.state.question.trim() === "") {
      this.setState({
        error: true,
        formError: "Please enter your question",
      });
      return false;
    }
    return true;
  }

  submitForm(event) {
    event.preventDefault();
    if (this.formValidation()) {
      console.log(this.state.text, this.state.email);
      const formData = {
        email: this.state.email,
        question: this.state.question,
        topics: this.state.topics,
      };
      fetch("/api/new-question-advisor", {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(() => {
          console.log("senttt");
          this.setState({ formSuccess: true });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  render() {
    const Answer = ({ answer }) =>
      answer.split("\\n\\n").map((item, i) => {
        return (
          <Fragment>
            <Paragraph key={i}>{item}</Paragraph>
            <br />
          </Fragment>
        );
      });

    const Hits = ({ hits }) => (
      <div>
        <DisclaimerMessage centered>
          This is not designed to be used as a substitute for real medical or
          mental health advice. You can speak to a therapist in real time by{" "}
          <a target="_blank" href="https://web.spill.chat/book/quilter/one-off">
            booking a session on Spill
          </a>
          .
        </DisclaimerMessage>
        {hits.map((hit) => (
          <HitTopicBox>
            <B>
              <Paragraph>{hit.topic}</Paragraph>
            </B>
            <br />
            <Answer answer={hit.answer} />
          </HitTopicBox>
        ))}
      </div>
    );
    const CustomHits = connectHits(Hits);

    const SearchBox = ({ currentRefinement, refine }) => (
      <SearchInput
        type="search"
        value={currentRefinement}
        onChange={(event) => refine(event.currentTarget.value)}
        placeholder="Anxiety, coronavirus, relationships..."
      />
    );
    const CustomSearchBox = connectSearchBox(SearchBox);

    return (
      <Fragment>
        <Helmet>
          <title>Browse real advice from therapists</title>
        </Helmet>
        <CenteredSection>
          <Container>
            {this.state.formSuccess ? (
              <Fragment>
                <SectionTitle centered>Topic submitted</SectionTitle>
                <BigWrapper>
                  <Paragraph centered>
                    Check back in two working days to search for the topic again
                    and see the therapist's response.
                  </Paragraph>
                </BigWrapper>
              </Fragment>
            ) : (
              <Fragment>
                <SectionTitle centered>
                  Browse real advice from therapists
                </SectionTitle>
                <Wrapper>
                  <Paragraph centered>
                    Search a topic below or request your own topic be added
                  </Paragraph>

                  <InstantSearch searchClient={searchClient} indexName="aat">
                    <Configure hitsPerPage={3} distinct />
                    <CustomSearchBox />
                    <CustomHits />
                  </InstantSearch>
                  <Separator />
                  <Fragment>
                    <LongSectionSubTitle centered>
                      Can't find the answer you're looking for?
                    </LongSectionSubTitle>
                    <Paragraph centered>
                      Submit a topic anonymously and a therapist will add their
                      thoughts on the topic to this public database within two
                      working days.
                    </Paragraph>
                    <Form
                      onSubmit={(event) => {
                        this.submitForm(event);
                      }}
                      id="questionForm"
                    >
                      <Label for="question">Question</Label>
                      <Input
                        placeholder="How can I sleep better?"
                        value={this.state.question}
                        onChange={this.onChangeQuestion}
                        form="questionForm"
                        type="text"
                        id="question"
                        title="question"
                        aria-label="
                    question"
                      />
                      <Label for="topics">Topics</Label>
                      <Input
                        placeholder="Sleep, sleep problems"
                        value={this.state.topics}
                        onChange={this.onChangeTopics}
                        form="questionForm"
                        type="text"
                        id="topics"
                        title="topics"
                        aria-label="
                    topics"
                      />
                      <Label for="email">
                        Email address (optional). Used for general notifcations
                        only.
                      </Label>
                      <Input
                        placeholder="example@gmail.com"
                        value={this.state.email}
                        onChange={this.onChangeEmail}
                        form="questionForm"
                        type="email"
                        id="email"
                        title="email"
                        aria-label="
                    email"
                      />
                    </Form>
                    <CenteredDiv>
                      <BorderButton
                        text="submit"
                        borderColor={theme.colors.blue.normal}
                        fontColor={theme.colors.blue.normal}
                        action={(event) => {
                          this.submitForm(event);
                        }}
                      />
                    </CenteredDiv>
                    {this.state.error && (
                      <ErrorMessage centered>
                        {this.state.formError}
                      </ErrorMessage>
                    )}
                  </Fragment>
                </Wrapper>
              </Fragment>
            )}
          </Container>
        </CenteredSection>
      </Fragment>
    );
  }
}

export default QuestionDatabase;
