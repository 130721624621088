import styled from "styled-components";
import devices from "./devices";

export const Section = styled.section`
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => `${theme.spacing.s3} ${theme.spacing.s3}`};
  margin: 0 auto;
  display: flex;
  justify-content: center;

  @media ${devices.tablet} {
    padding: ${({ theme }) => `${theme.spacing.m1} ${theme.spacing.m1}`};
  }
  @media ${devices.desktop} {
    max-width: 1024px;
    padding: ${({ theme }) => `${theme.spacing.l1} ${theme.spacing.m1}`};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${devices.tablet} {
    flex-direction: row;
    width: 80%;
    width: 30rem;
    justify-content: space-between;
  }
  @media ${devices.desktop} {
    flex-direction: row;
    width: 34rem;
    justify-content: space-between;
  }
`;

export const TwoColumnSection = styled(Section)`
  display: flex;
  flex-direction: column;

  @media ${devices.tablet} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: ${({ theme }) => theme.spacing.m1};
    grid-auto-rows: 1fr;
    grid-auto-rows: 1fr;
  }
  @media ${devices.desktop} {
    grid-column-gap: ${({ theme }) => theme.spacing.s3};
    padding-bottom: ${({ theme }) => theme.spacing.m1};
    padding-top: ${({ theme }) => theme.spacing.m1};
  }
`;

export const CopySection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${devices.tablet} {
    min-width: 22rem;
    width: 75%;
    padding-left: 20%;
  }
`;

export const Drawing = styled.img`
  width: 90%;
  height: auto;
  justify-self: center;
  margin: 0 auto;

  @media ${devices.tablet} {
    width: ${({ theme }) => theme.spacing.l3};
    display: block;
  }

  @media ${devices.desktop} {
    width: ${({ theme }) => theme.spacing.xl1};
  }
`;

export const DrawingPadding = styled(Drawing)`
  margin-top: ${({ theme }) => theme.spacing.m2};
`;

export const ShadowImg = styled.img`
  width: ${({ theme }) => theme.spacing.l3};
  height: auto;
  display: none;
  justify-self: center;
  margin: 0 auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 2rem;

  @media ${devices.tablet} {
    width: ${({ theme }) => theme.spacing.l3};
    display: block;
  }

  @media ${devices.desktop} {
    width: ${({ theme }) => theme.spacing.xl1};
  }
`;

export const Img = styled.img`
  width: 80%;
  height: auto;
  justify-self: center;
  margin: 0 auto;
  margin-bottom: 2rem;
  margin-top: 4rem;

  @media ${devices.tablet} {
    height: ${({ theme }) => theme.spacing.l1};
    width: auto;
    display: block;
  }

  @media ${devices.desktop} {
    height: ${({ theme }) => theme.spacing.l2};
    width: auto;
  }
`;

export const FlexImg = styled.img`
  width: 100%;
  height: auto;
`;

export const CenteredSection = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;

  @media ${devices.desktop} {
    min-height: 60vh;
  }
`;

export const TitleSection = styled.section`
  background-color: ${({ theme }) => theme.colors.white};
  padding-top: ${({ theme }) => theme.spacing.m1};
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${devices.tablet} {
    padding-top: ${({ theme }) => theme.spacing.m2};
  }
  @media ${devices.desktop} {
    max-width: 1024px;
    padding-top: ${({ theme }) => theme.spacing.m3};
  }
`;

export const LinkText = styled.a`
  color: ${({ theme }) => theme.colors.blue.normal};
  &:hover {
    color: ${({ theme }) => theme.colors.blue.light};
  }
`;
