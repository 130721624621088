import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import Separator from "../../components/separator/index";
import {
  CenteredSection,
  TitleSection,
  Img,
} from "../../style/base-components";
import { WhiteNavigationBar } from "../../components/navigation";
import { SectionTitle, LongSectionTitle, Paragraph, B } from "../../style/text";
import theme from "../../style/theme";
import { Wrapper } from "./style";
import WorkImg from "../../assets/resources/work-reading.png";
import RelationshipsImg from "../../assets/resources/relationships-reading.png";
import SelfImg from "../../assets/resources/self-reading.png";

class Reading extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Reccomendations | Spill</title>
          <meta
            name="description"
            content="Spill lets you message your designated counsellor every day. Affordable talking therapy whenever and wherever you need it."
          />
          <meta
            name="keywords"
            content="Spill,Mental health,counselling,counsellor,chat,online,therapy,help"
          />
        </Helmet>
        <WhiteNavigationBar showNav />
        <TitleSection>
          <SectionTitle centered>Netflix for the soul</SectionTitle>
        </TitleSection>
        <CenteredSection>
          <Wrapper>
            <Img src={WorkImg} alt="Person working at a computer" />
            <LongSectionTitle>Work</LongSectionTitle>
            <Paragraph>
              <B>
                Starter - this is basic emotional intelligence stuff everyone
                should read.
              </B>
              1.{" "}
              <a href="https://www.ted.com/talks/shawn_achor_the_happy_secret_to_better_work?language=en">
                {" "}
                The Happy Secret to Better Work by Shawn Anchor.
              </a>{" "}
              This fun TED Talk is a great introduction to the concept of
              positive psychology: don’t succeed so you become happy, but get
              happy so you can succeed. Watch if you want to feel happier.
              <br />
              <br />
              2.{" "}
              <a href="https://www.youtube.com/watch?v=MKlx1DLa9EA&list=PLHMsX2GvtDqcwLRIGXRVE_joa-OsU3gtT">
                On Work You Love by Benjamin Todd.
              </a>{" "}
              Should we follow our ‘passion’ in order to find work we ‘love’?
              Research shows that’s no certain path to happiness. Instead, try
              doing what’s valuable. Watch if you want to feel challenged.
              <br />
              <br />
              3.{" "}
              <a href="https://www.youtube.com/watch?v=vM39qhXle4g&list=PLHMsX2GvtDqcwLRIGXRVE_joa-OsU3gtT">
                The Habits of Effective Artists by Andrew Price.
              </a>{" "}
              What are 7 habits that could make the difference between seeing
              through that art project and not doing so? In conduction with
              ‘Atomic Habits’, below, this might just make your dream a reality.
              Watch if you want to feel motivated.
              <br />
              <br />
              <B>Advanced - for the emotionally sophisticated.</B>
              1.{" "}
              <a href="https://www.youtube.com/watch?v=fEvKo90qBns">
                The Lean Startup by Eric Ries.
              </a>{" "}
              This Talk at Google is a summary of the book with the same name,
              by its author. Anyone can be an entrepreneur if they have the
              right mindset. Watch to feel motivated.
              <br />
              <br />
              2.{" "}
              <a href="https://www.youtube.com/watch?v=Q8ApZXWgJq4">
                Atomic Habits by James Clear.
              </a>{" "}
              Good habits make time work for you: bad habits make time work
              against you. You are what you do most often. And small changed
              compound into huge ones. Watch if you want to feel inspired and
              motivated.
              <br />
              <br />
              3.{" "}
              <a href="https://www.youtube.com/watch?v=PyTBza9Nhrk&list=PLHMsX2GvtDqeCL2aqEw85iHko49Z7cqB0">
                Fulfilment at Work by Alain de Botton.
              </a>{" "}
              Work is part of our identity: at dinner parties it’s the first
              piece of information we reveal about ourselves. Can work be
              meaningful? How can we make it so? Watch if you want to feel more
              self-aware.
            </Paragraph>
          </Wrapper>
        </CenteredSection>
        <Separator />
        <CenteredSection>
          <Wrapper>
            <Img src={RelationshipsImg} alt="Woman holding a heart balloon" />
            <LongSectionTitle>Relationships</LongSectionTitle>
            <Paragraph>
              <B>
                Starter - this is basic emotional intelligence stuff everyone
                should read.
              </B>
              1.{" "}
              <a href="https://www.youtube.com/watch?v=R2v8TywXjLA&list=PLHMsX2GvtDqeCL2aqEw85iHko49Z7cqB0">
                Why Romantics are Ruining Love by Alain de Botton.
              </a>{" "}
              This Google Zeitgeist talk sees London-based philosopher and
              writer Alain de Botton put forward an intriguing argument -
              romanticism is killing love and reviewing our expectations might
              just be the key to better relationships. Watch if you want to feel
              optimistic.
              <br />
              <br />
              2.{" "}
              <a href="https://www.youtube.com/watch?v=osd9AKRCFRM&list=PLHMsX2GvtDqeCL2aqEw85iHko49Z7cqB0">
                On Sex by Alain de Botton.
              </a>{" "}
              We might be living in liberated times, but our societal
              relationship with sex is far from perfect. Even while sex is
              everywhere, good sex often feels harder and harder to attain. Why?
              Watch if you want to feel emotionally woke.
              <br />
              <br />
              3.{" "}
              <a href="https://www.youtube.com/watch?v=sa0RUmGTCYY">
                The secret to desire in a long-term relationship by Esther
                Perel.
              </a>{" "}
              In this classic TED Talk, our old-time favourite Esther Perel
              brings a clear-headed understanding to the challenges faced by
              modern relationships. A must watch if you are or ever want to be
              in a long-term relationship. Watch if you want to feel hopeful.
              <br />
              <br />
              <B>Advanced - for the emotionally sophisticated.</B>
              1.{" "}
              <a href="https://www.youtube.com/watch?v=qrvf5wYumhg">
                Conscious Uncoupling by Katherine Woodward.
              </a>{" "}
              Conscious Uncoupling became a thing when Gwyneth Paltrow and Chris
              Martin did it some years ago. Though Katherine Woodward’s style
              might not be everyone’s cup of tea, it’s an interesting idea to
              consider: how to do breakups well. Watch if you want to feel
              intrigued.
              <br />
              <br />
              2.{" "}
              <a href="https://whereshouldwebegin.estherperel.com/">
                Where do We Begin by Esther Perel.
              </a>{" "}
              This podcast is one of the best relationship guides out there:
              real couples therapy with real couples, there are heartwarming and
              humbling stories. Watch if you want to feel inspired and hopeful.
              <br />
              <br />
              3.{" "}
              <a href="https://www.youtube.com/watch?v=m3MjhYFpJMk">
                Modern Love by Amazon.
              </a>{" "}
              This heart-warming and wise collection of stories might just give
              you a bit of inspiration when it comes to love in the modern
              world. Watch if you want to feel happy.
            </Paragraph>
          </Wrapper>
        </CenteredSection>
        <Separator />
        <CenteredSection>
          <Wrapper>
            <Img src={SelfImg} alt="Man reading a book" />
            <LongSectionTitle>Self</LongSectionTitle>
            <Paragraph>
              <B>
                Starter - this is basic emotional intelligence stuff everyone
                should read.
              </B>
              1.{" "}
              <a href="https://www.youtube.com/watch?v=8CrOL-ydFMI&list=PLHMsX2GvtDqcwLRIGXRVE_joa-OsU3gtT&index=4&t=0s">
                This is Water by David Foster Wallace.
              </a>{" "}
              A personal favourite of ours, this commencement speech is one of
              the loveliest as well as cleverest invitations to practicing
              self-awareness ever made. Watch if you want to feel challenged to
              think differently.
              <br />
              <br />
              2.{" "}
              <a href="https://www.youtube.com/watch?v=NgDGlcxYrhQ&list=PLHMsX2GvtDqcwLRIGXRVE_joa-OsU3gtT&index=18&t=0s">
                Paper Towns by John Green.
              </a>{" "}
              This funny and charming TED Talk is an ode to learning and the
              incredible superpowers that it can grant us when we adopt it as a
              habit. It's also an introduction to the world of online learning,
              which - if you don't already - you should really know about, as
              it's the future. Watch if you want to feel inspired and clued in.
              <br />
              <br />
              3.{" "}
              <a href="https://www.youtube.com/watch?v=h-rRgpPbR5w&list=PLHMsX2GvtDqcwLRIGXRVE_joa-OsU3gtT">
                Why You Feel What You Feel by Alan Watkins.
              </a>
              This informative and funny TED Talk is a basic lesson in
              developmental psychology. It's the first thing you need to learn
              before you can begin to transform your own reality, like Neo from
              The Matrix. Watch if you want to feel empowered.
              <br />
              <br />
              <B>Advanced - for the emotionally sophisticated.</B>
              1.{" "}
              <a href="https://www.youtube.com/watch?v=CjVQJdIrDJ0&list=PLHMsX2GvtDqcwLRIGXRVE_joa-OsU3gtT">
                Thinking, Fast and Slow by Daniel Kahneman.
              </a>{" "}
              This Talk at Google by Nobel Prize winner Daniel Kahneman is an
              overview of his groundbreaking book of the same title. The last
              few decades have revolutionised our understanding of the mind.
              Read if you want to feel both clever and wise.
              <br />
              <br />
              2.{" "}
              <a href="https://www.youtube.com/watch?v=y0gtnOXAp-U&list=PLHMsX2GvtDqcwLRIGXRVE_joa-OsU3gtT">
                The Science of Self-Compassion by Kristin Neff.
              </a>{" "}
              Feeling like all this talk about self-compassion is just
              touchy-feely hippy nonsense? Well, hold that thought. Maybe some
              hard cold science in this clear-minded Talk at Google will change
              your mind. Watch if you want to feel challenged.
              <br />
              <br />
              3.{" "}
              <a href="https://www.youtube.com/playlist?list=PL8dPuuaLjXtOPRKzVLY0jJY-uHOH9KVU6">
                Crash Course Psychology by Crash Course.
              </a>{" "}
              This introductory ‘lecture’ series is a collection of videos
              designed to teach you the basic concept of psychology in
              fun-to-watch 10min videos. Watch if you want to learn about the
              mind.
            </Paragraph>
          </Wrapper>
        </CenteredSection>
      </Fragment>
    );
  }
}

export default Reading;
