import React from "react";

import {
  Container,
  DiamondWrapper,
  PinkDiamond,
  YellowDiamond,
  TealDiamond,
  BlueDiamond,
  Line,
  ImageContainer,
} from "./style";
import {
  TwoColumnSection,
  CopySection,
  Drawing,
  CenteredSection,
  ShadowImg,
  FlexImg,
  TitleSection,
} from "../../style/base-components";
import SpillLogo from "../../assets/logo/spill-logo.png";
import QuilterLogo from "../../assets/logo/quilter-logo.png";
import { SectionTitle, Paragraph, SmallParagraph } from "../../style/text";

const LogoSection = () => (
  <Container>
    <ImageContainer>
      <FlexImg src={SpillLogo} alt="Spill logo" />
    </ImageContainer>
  </Container>
);

export default LogoSection;
