import styled from "styled-components";
import devices from "../../style/devices";

export const Wrapper = styled.nav`
  top: 0;
  position: sticky;
  position: -webkit-sticky;
  z-index: 20;
`;

export const Navigation = styled.nav`
  width: 100%;
  height: 8vh;
  position: sticky;
  position: -webkit-sticky;
  min-height: 4rem;
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
  justify-content: space-between;
  box-shadow: 0 4px 6px hsla(0, 0%, 0%, 0.1);
  top: 0;
  transition: all 0.3s ease-out;
  align-items: center;
  z-index: 20;
  opacity: ${({ showNav }) => (showNav ? 1 : 0)};
`;

export const TransparentNavigation = styled(Navigation)`
  background-color: ${({ theme }) => theme.colors.blue.normal};
  box-shadow: none;
`;

export const Logo = styled.img`
  width: auto;
  height: 50%;
  padding: ${({ theme }) => `${theme.spacing.s1} ${theme.spacing.s2}`};
`;

export const TransparentNavLogo = styled(Logo)``;

export const NavItems = styled.ul`
  display: none;

  @media ${devices.desktop} {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
  }
`;

export const GoBack = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: ${({ theme }) => theme.spacing.s3};
  padding: 0;
`;

export const NavItem = styled.li`
  text-decoration: none;
  list-style-type: none;
  padding: ${({ theme }) => `${theme.spacing.s1} ${theme.spacing.s1}`};

  @media ${devices.desktop} {
    &:last-of-type {
      padding-right: ${({ theme }) => theme.spacing.s3};
    }
  }
`;

export const NavLink = styled.a`
  font-family: ${({ theme }) => theme.font.family.MarkPro};
  font-weight: bold;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.blue.normal};
  cursor: pointer;
  text-decoration: none;
  font-size: ${({ theme }) => theme.font.size[1]};
  letter-spacing: ${({ theme }) => theme.letterSpacing.large};
  background-image: ${({ theme }) =>
    `linear-gradient(${theme.colors.blue.normal}, ${
      theme.colors.blue.normal
    }), linear-gradient(transparent, transparent)`};
  background-size: 0 2px, auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  transition: all 0.2s ease-out;
  padding-bottom: ${({ theme }) => theme.spacing.xs1};
  &:hover {
    background-size: 100% 2px, auto;
  }
`;

export const TransparentNavLink = styled(NavLink)`
  color: ${({ theme }) => theme.colors.blue.extraLight};
  background-image: ${({ theme }) =>
    `linear-gradient(${theme.colors.blue.extraLight}, ${
      theme.colors.blue.extraLight
    }), linear-gradient(transparent, transparent)`};
  @media screen and (max-width: 600px) {
    margin-right: 20px;
  }
`;

export const MenuButton = styled.button`
  display: flex;
  color: ${({ theme }) => theme.colors.blue.normal};
  font-family: ${({ theme }) => theme.font.family.MarkPro};
  font-weight: bold;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.font.size[2]};
  border: 0;
  background-color: transparent;
  padding: 0;
  outline: 0;

  @media ${devices.desktop} {
    display: none;
  }
`;

export const TransparentNavMenuButton = styled(MenuButton)`
  color: ${({ theme }) => theme.colors.blue.light};
`;

export const MenuDropdown = styled.ul`
  background-color: ${({ theme }) => theme.colors.white};
  height: 100vh;
  width: 100vw;
  top: ${(props) => (props.showMenu ? "4rem" : "-300vh")};
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  transition: all 0.3s ease-out;
  z-index: 8;
  padding: 0;

  @media ${devices.desktop} {
    display: none;
  }
`;

export const TransparentNavMenuDropdown = styled(MenuDropdown)`
  background-color: ${({ theme }) => theme.colors.blue.normal};
  margin-right: 4em;
`;

export const LogoStyle = styled.div`
  height: 7vh;
`;
