import styled from "styled-components";
import devices from "../../style/devices";
import { Link } from "react-router-dom";
import { Section } from "../../style/base-components";
import { Paragraph, SmallParagraph } from "../../style/text";

export const Container = styled.div``;
export const Form = styled.form`
  padding: ${({ theme }) => `${theme.spacing.m1} 0`};
  display: flex;
  flex-direction: column;
  width: 100%;
  @media ${devices.tablet} {
    width: 100%;
    margin: 0 auto;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media ${devices.tablet} {
    max-width: 35rem;
  }
`;

export const HitTopicBox = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.m1};
`;

export const BigWrapper = styled(Wrapper)`
  min-height: 90vh;
  @media ${devices.tablet} {
    min-height: 50vh;
  }
`;
export const ErrorMessage = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.tertiary.normal};
  padding-top: ${({ theme }) => theme.spacing.s2};
`;

export const DisclaimerMessage = styled(SmallParagraph)`
  padding-bottom: ${({ theme }) => theme.spacing.m1};
`;

export const Label = styled.label`
  font-family: ${({ theme }) => theme.font.family.Lato};
  color: ${({ theme }) => theme.colors.grey[900]};
  line-height: ${({ theme }) => theme.lineHeight.normal};
  font-size: ${({ theme }) => theme.font.size[3]};
  text-align: ${({ centered }) => (centered ? "center" : "left")};
  margin-bottom: ${({ theme }) => theme.spacing.xs3};

  @media ${devices.desktop} {
    font-size: ${({ theme }) => theme.font.size[3]};
  }
`;

export const SearchInput = styled.input`
  font-family: ${({ theme }) => theme.font.family.lato};
  border: 0px solid transparent;
  font-weight: 100;
  border-bottom: solid ${({ theme }) => theme.colors.primary.normal} 0.1rem;
  padding: ${({ theme }) => theme.spacing.xs3};
  font-size: ${({ theme }) => theme.font.size[6]};
  background-color: ${({ theme }) => theme.colors.white};
  outline: 0;
  margin-top: ${({ theme }) => theme.spacing.s1};
  width: auto;
  margin-bottom: ${({ theme }) => theme.spacing.m1};
  margin-top: ${({ theme }) => theme.spacing.m2};
`;

export const Input = styled.input`
  font-family: ${({ theme }) => theme.font.family.lato};
  background-color: ${({ theme }) => theme.colors.grey[200]};
  border: 0;
  border: solid 0.1rem;
  border-radius: 0.5rem;
  border-color: transparent;
  padding: ${({ theme }) => theme.spacing.xs3};
  margin-bottom: ${({ theme }) => theme.spacing.s3};
  font-size: ${({ theme }) => theme.font.size[3]};
  &:focus {
    background-color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.grey[300]};
    outline: 0;
  }
`;
export const CenteredDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const CenteredSection = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 90vh;

  @media ${devices.desktop} {
    min-height: 60vh;
  }
`;
