import React, { Fragment, Component } from "react";
import { Helmet } from "react-helmet";
import { SectionTitle, Paragraph } from "../../style/text";
import {
  Container,
  LongCopy,
  Input,
  EmailInput,
  Form,
  Wrapper,
  BigWrapper,
  CenteredDiv,
  ErrorMessage,
  CenteredSection,
} from "./style";
import { BorderButton } from "../../components/buttons/index";
import theme from "../../style/theme";
import * as qs from "query-string";
import { WhiteNavigationBar } from "../../components/navigation";

class Question extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      text: "",
      formSuccess: false,
      error: false,
      formError: "",
      userType: "user",
    };
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeText = this.onChangeText.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.formValidation = this.formValidation.bind(this);
  }
  onChangeEmail(event) {
    this.setState({ email: event.target.value });
  }

  onChangeText(event) {
    this.setState({ text: event.target.value });
  }

  formValidation() {
    if (this.state.text.trim() === "") {
      this.setState({
        error: true,
        formError: "Please enter your question",
      });
      return false;
    }
    if (this.state.email.indexOf("@") == -1) {
      this.setState({
        error: true,
        formError: "Please enter a valid email",
      });
      return false;
    }
    return true;
  }

  submitForm(event) {
    event.preventDefault();
    if (this.formValidation()) {
      console.log(this.state.text, this.state.email);
      const formData = {
        email: this.state.email,
        text: this.state.text,
      };
      fetch("/api/new-question", {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(() => {
          console.log("senttt");
          this.setState({ formSuccess: true });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Question</title>
        </Helmet>
        <WhiteNavigationBar showNav />
        <CenteredSection>
          <Container>
            {this.state.formSuccess ? (
              <Fragment>
                <SectionTitle centered>Question delivered</SectionTitle>
                <BigWrapper>
                  <Paragraph centered>
                    We'll reply by email the next working day
                  </Paragraph>
                </BigWrapper>
              </Fragment>
            ) : (
              <Fragment>
                <SectionTitle centered>
                  Get advice from a therapist
                </SectionTitle>
                <Wrapper>
                  <Paragraph centered>
                    Ask a question and get a reply the next day via email
                  </Paragraph>
                  <Form
                    onSubmit={(event) => {
                      this.submitForm(event);
                    }}
                    id="questionForm"
                  >
                    <Input
                      placeholder="Write your question here"
                      value={this.state.text}
                      onChange={this.onChangeText}
                      form="questionForm"
                    />
                    <label for="email" style={{ display: "none" }}>
                      Email Address
                    </label>

                    <EmailInput
                      placeholder="Email Address"
                      type="email"
                      id="email"
                      value={this.state.name}
                      onChange={this.onChangeEmail}
                      title="email"
                      aria-label="email"
                    />
                  </Form>

                  <CenteredDiv>
                    <BorderButton
                      text="submit"
                      borderColor={theme.colors.blue.normal}
                      fontColor={theme.colors.blue.normal}
                      action={(event) => {
                        this.submitForm(event);
                      }}
                    />
                  </CenteredDiv>
                  {this.state.error && (
                    <ErrorMessage centered>{this.state.formError}</ErrorMessage>
                  )}
                </Wrapper>
              </Fragment>
            )}
          </Container>
        </CenteredSection>
      </Fragment>
    );
  }
}

export default Question;
