import styled from "styled-components";
import devices from "./devices";
import { Link } from "react-router-dom";

export const SectionTitle = styled.h1`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.font.family.MarkPro};
  font-weight: bold;
  font-size: ${({ theme }) => theme.font.size[7]};
  text-align: ${({ centered }) => (centered ? "center" : "left")};
  letter-spacing: ${({ theme }) => theme.letterSpacing.small};
  padding-bottom: ${({ theme }) => theme.spacing.s1};
  line-height: normal;

  @media ${devices.tablet} {
    font-size: ${({ theme }) => theme.font.size[8]};
    max-width: 50vw;
    padding-bottom: ${({ theme }) => theme.spacing.s2};
  }

  @media ${devices.desktop} {
    font-size: ${({ theme }) => theme.font.size[8]};
  }
`;

export const SectionTitleCTA = styled(SectionTitle)`
  @media ${devices.tablet} {
    padding-bottom: ${({ theme }) => theme.spacing.xs1};
  }
`;

export const LongSectionTitle = styled.h2`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.font.family.Lato};
  letter-spacing: 0;
  font-weight: normal;
  line-height: 1.3;
  padding-top: ${({ theme }) => theme.spacing.s3};
  padding-bottom: ${({ theme }) => theme.spacing.s1};
  font-size: ${({ theme }) => theme.font.size[7]};

  @media ${devices.tablet} {
    font-size: ${({ theme }) => theme.font.size[8]};
    padding-top: ${({ theme }) => theme.spacing.m1};
    padding-bottom: ${({ theme }) => theme.spacing.s2};
  }
`;
export const LongSectionTitleNoPadding = styled.h2`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.font.family.Lato};
  letter-spacing: 0;
  font-weight: normal;
  line-height: 1.3;
  padding-bottom: ${({ theme }) => theme.spacing.s1};
  font-size: ${({ theme }) => theme.font.size[7]};

  @media ${devices.tablet} {
    font-size: ${({ theme }) => theme.font.size[8]};
    padding-bottom: ${({ theme }) => theme.spacing.s2};
  }
`;

export const LongSectionSubTitle = styled.h4`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.font.family.Lato};
  letter-spacing: 0;
  font-weight: bold;
  text-align: ${({ centered }) => (centered ? "center" : "left")};
  padding-top: ${({ theme }) => theme.spacing.s3};
  padding-bottom: ${({ theme }) => theme.spacing.s1};
  font-size: ${({ theme }) => theme.font.size[5]};

  @media ${devices.tablet} {
    font-size: ${({ theme }) => theme.font.size[6]};
    padding-top: ${({ theme }) => theme.spacing.m1};
    padding-bottom: ${({ theme }) => theme.spacing.s2};
  }
`;

export const Paragraph = styled.p`
  font-family: ${({ theme }) => theme.font.family.Lato};
  color: ${({ theme }) => theme.colors.grey[900]};
  line-height: ${({ theme }) => theme.lineHeight.normal};
  font-size: ${({ theme }) => theme.font.size[4]};
  text-align: ${({ centered }) => (centered ? "center" : "left")};

  @media ${devices.desktop} {
    font-size: ${({ theme }) => theme.font.size[4]};
  }
`;

export const ParagraphLink = styled(Paragraph)`
  cursor: pointer;
  color: #001e49;
  text-decoration: underline;
`;

export const WhiteParagraph = styled.p`
  margin-top: ${({ theme }) => theme.spacing.s1};
  font-family: ${({ theme }) => theme.font.family.Lato};
  color: ${({ theme }) => theme.colors.white};
  line-height: ${({ theme }) => theme.lineHeight.normal};
  font-size: ${({ theme }) => theme.font.size[4]};
  text-align: ${({ centered }) => (centered ? "center" : "left")};

  @media ${devices.desktop} {
    font-size: ${({ theme }) => theme.font.size[4]};
  }
`;

export const LongParagraph = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.grey[900]};

  @media ${devices.desktop} {
    font-size: ${({ theme }) => theme.font.size[5]};
  }
`;

export const ThinParagraph = styled(Paragraph)`
  max-width: 90%;
`;

export const SubTitle = styled.h2`
  font-family: ${({ theme }) => theme.font.family.MarkPro};
  font-weight: bold;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.grey[800]};
  font-size: ${({ theme }) => theme.font.size[3]};
  padding-bottom: ${({ theme }) => theme.spacing.xs1};
  text-align: ${({ centered }) => (centered ? "center" : "left")};
  @media ${devices.tablet} {
  }

  @media ${devices.desktop} {
  }
`;

export const SmallParagraph = styled(Paragraph)`
  font-size: ${({ theme }) => theme.font.size[3]};
  hyphens: auto;

  @media ${devices.desktop} {
    font-size: ${({ theme }) => theme.font.size[3]};
    max-width: 80%;
    margin: 0 auto;
  }
`;

export const SmallHeading = styled.h4`
  font-family: ${({ theme }) => theme.font.family.Lato};
  font-weight: bold;
  font-size: ${({ theme }) => theme.font.size[5]};
  text-align: center;
`;

export const ResourceLink = styled(Link)`
  font-family: ${({ theme }) => theme.font.family.Lato};
  line-height: ${({ theme }) => theme.lineHeight.normal};
  font-size: ${({ theme }) => theme.font.size[4]};
  text-align: ${({ centered }) => (centered ? "center" : "left")};

  @media ${devices.desktop} {
    font-size: ${({ theme }) => theme.font.size[4]};
  }
  margin-top: 0.7rem;
  color: #001e49;
  text-decoration: none;
  font-weight: bold;
  font-size: ${({ theme }) => theme.font.size[5]};
  :hover {
    color: ${({ theme }) => theme.colors.grey[600]};
  }
`;

export const ExternalResourceLink = styled.a`
  font-family: ${({ theme }) => theme.font.family.Lato};
  line-height: ${({ theme }) => theme.lineHeight.normal};
  font-size: ${({ theme }) => theme.font.size[4]};
  text-align: ${({ centered }) => (centered ? "center" : "left")};

  @media ${devices.desktop} {
    font-size: ${({ theme }) => theme.font.size[4]};
  }
  margin-top: 0.7rem;
  color: #001e49;
  text-decoration: none;
  font-weight: bold;
  font-size: ${({ theme }) => theme.font.size[5]};
  :hover {
    color: ${({ theme }) => theme.colors.grey[600]};
  }
`;
export const B = styled.p`
  font-weight: bold;
`;
